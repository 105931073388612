import { FC } from 'react'
import { Link } from '../Link'
import s from './Footer.module.css'

const Footer: FC = () => {
  return (
    <footer className={s.copyright}>
      Powered by
      <br />
      <Link href="https://xoda.com/" target="_blank">
        <img src="/images/logo.svg" alt="Xoda" />
      </Link>
    </footer>
  )
}

export { Footer }
