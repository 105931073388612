import { useCallback, useEffect, useState } from 'react'

const useCountdown = (beginning = 60) => {
  const [value, setValue] = useState(beginning)
  const [timer, setTimer] = useState<number>()

  const stop = useCallback(() => {
    clearInterval(timer)
    setTimer(undefined)
    setValue(beginning)
  }, [beginning, timer])

  const start = useCallback(() => {
    if (timer) {
      return
    }

    setTimer(
      window.setInterval(() => {
        setValue((prev) => prev - 1)
      }, 1000)
    )
  }, [timer])

  useEffect(() => {
    if (value <= 0) {
      stop()
    }
  }, [stop, value])

  return {
    value,
    start,
    stop,
    running: !!timer,
  }
}

export { useCountdown }
