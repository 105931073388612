import { FC } from 'react'
import s from './Amount.module.css'

const Amount: FC<AmountProps> = (props) => {
  const { items, total = 0 } = props

  return (
    <div className={s.root}>
      {items && (
        <table className={s.items}>
          <caption className={s.caption}>Payment Summary</caption>
          <tbody>
            {items.map((item) => (
              <tr key={item.name}>
                <th scope="row" className={s.name}>
                  {item.name}
                </th>
                <td>${item.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className={s.total}>
        Total: $
        {items ? items.reduce((pool, item) => pool + item.price, 0) : total}
      </div>
    </div>
  )
}

type AmountProps = {
  items?: { name: string; price: number }[]
  total?: number
}

export { Amount }
export type { AmountProps }
