import { FC, OptionHTMLAttributes, SelectHTMLAttributes } from 'react'
import { createBooleanDataset } from '../../helpers/others'
import s from './Select.module.css'

const Select: FC<SelectProps> = (props) => {
  const { block, options, ...passingProps } = props

  return (
    <select
      className={s.root}
      {...createBooleanDataset({ block })}
      {...passingProps}
    >
      {options.map((option) => (
        <option key={String(option.value)} value={option.value}>
          {option.label || option.value}
        </option>
      ))}
    </select>
  )
}

type SelectProps = Pick<
  SelectHTMLAttributes<HTMLSelectElement>,
  'name' | 'defaultValue' | 'required'
> & {
  block?: boolean
  options: Pick<OptionHTMLAttributes<HTMLOptionElement>, 'label' | 'value'>[]
}

export { Select }
export type { SelectProps }
