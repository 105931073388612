import clsx from 'clsx'
import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react'
import { createBooleanDataset } from '../../helpers/others'
import { Loading } from '../Loading'
import s from './Button.module.css'

const Button: FC<ButtonProps> = (props) => {
  const {
    variant,
    color,
    block,
    pending,
    disabled,
    className,
    children,
    ...passingProps
  } = props

  const finalDisabled = disabled || pending

  return (
    <button
      type="button"
      disabled={finalDisabled}
      className={clsx(s.root, className)}
      data-variant={variant}
      data-color={color}
      {...createBooleanDataset({ block })}
      {...passingProps}
    >
      {pending && <Loading inline />}
      {children}
    </button>
  )
}

type ButtonProps = PropsWithChildren &
  Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    'type' | 'disabled' | 'id' | 'className' | 'onClick' | 'children'
  > & {
    variant?: 'ghost' | 'text'
    color?: 'black' | 'white'
    block?: boolean
    pending?: boolean
  }

export { Button }
export type { ButtonProps }
