import {
  Elements,
  PaymentElement,
  PaymentElementProps,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe, StripeError } from '@stripe/stripe-js'
import { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import toast from 'react-hot-toast'
import { Button } from '../Button'
import { Loading } from '../Loading'
import s from './StripeSetup.module.css'

const StripeSetup: FC<StripeSetupProps> = (props) => {
  const { connectedAccountId, clientSecret, customerId, onFinish } = props

  const params = new URLSearchParams(window.location.search)
  const callbackClientSecret = params.get('setup_intent_client_secret')
  const callbackPaymentMethodType = params.get('payment_method_type')
  const callbackCustomerId = params.get('customer_id')

  const stripePromise = useMemo(
    () =>
      connectedAccountId
        ? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!, {
            stripeAccount: connectedAccountId,
          })
        : null,
    [connectedAccountId]
  )

  if (callbackClientSecret) {
    return (
      <div className={s.root}>
        <Elements stripe={stripePromise}>
          <StripeCallback
            clientSecret={callbackClientSecret}
            paymentMethodType={callbackPaymentMethodType}
            customerId={callbackCustomerId}
            onFinish={onFinish}
          />
        </Elements>
      </div>
    )
  }

  if (clientSecret) {
    return (
      <div className={s.root}>
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance: { theme: 'flat' } }}
        >
          <StripeForm customerId={customerId} />
        </Elements>
      </div>
    )
  }

  return null
}

const StripeForm: FC<StripeFormProps> = (props) => {
  const { customerId } = props

  const stripe = useStripe()
  const elements = useElements()

  const paymentMethodType = useRef<string>()

  const handleChange: Required<PaymentElementProps>['onChange'] = useCallback(
    (e) => {
      paymentMethodType.current = e.value.type
    },
    []
  )

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const url = new URL(window.location.href)

    url.searchParams.set(
      'payment_method_type',
      String(paymentMethodType.current)
    )

    // Keep the first value to avoid customer ID and payment method unmatching.
    url.searchParams.set('customer_id', String(customerId))

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Handle both payment collection and its callback by redirecting to current page itself.
        return_url: url.toString(),
      },
    })

    if (error) {
      showError(error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onChange={handleChange} />
      <div className={s.actions}>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  )
}

const StripeCallback: FC<StripeCallbackProps> = (props) => {
  const { clientSecret, paymentMethodType, customerId, onFinish } = props

  const stripe = useStripe()

  useEffect(() => {
    if (!(clientSecret && stripe)) {
      return
    }

    ;(async () => {
      const { setupIntent, error } = await stripe.retrieveSetupIntent(
        clientSecret
      )

      if (error) {
        showError(error)
        return
      }

      onFinish(setupIntent, paymentMethodType, customerId)
    })()
  }, [clientSecret, customerId, onFinish, paymentMethodType, stripe])

  return <Loading />
}

const showError = (error: Error | StripeError) => {
  toast.error(String(error.message))
}

type StripeSetupProps = Pick<StripeCallbackProps, 'onFinish'> &
  Pick<StripeFormProps, 'customerId'> & {
    connectedAccountId: string | null
    clientSecret: string | null
  }

type StripeFormProps = {
  customerId: string | null
}

type StripeCallbackProps = {
  clientSecret: string | null
  paymentMethodType: string | null
  customerId: string | null
  onFinish: (
    setupIntent: import('@stripe/stripe-js').SetupIntent,
    paymentMethodType: StripeCallbackProps['paymentMethodType'],
    customerId: StripeCallbackProps['customerId']
  ) => void
}

export { StripeSetup, showError }
export type { StripeSetupProps }
