import { atom } from 'recoil'

const configState = atom<Config | undefined>({
  key: 'config',
  default: undefined,
  effects: [
    ({ setSelf, onSet }) => {
      const key = 'config'

      const stored = sessionStorage.getItem(key)

      if (stored) {
        setSelf(JSON.parse(stored))
      }

      onSet((value) => {
        if (!value) {
          sessionStorage.removeItem(key)
          return
        }

        sessionStorage.setItem(key, JSON.stringify(value))
      })
    },
  ],
})

type Config = Dto.Integration['customisation']

export type { Config }
export { configState }
