import { FC, useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import {
  PaymentOptionsType,
  PaymentProvider,
  PaymentProviderType,
} from '../../third-party/PaymentProvider'
import { PaymentProviderFactory } from '../../third-party/PaymentProviderFactory'
import { CardExpiryInput } from '../CardExpiryInput'
import { CardInput } from '../CardInput'
import { Field } from '../Field'
import { Input } from '../Input'

const CreditCardFields: FC<CreditCardFieldsProps> = (props) => {
  const { payment, onSucceed, onError } = props

  const handleError = useCallback(
    (error: unknown) => {
      toast.error(String(error))
      onError(error)
    },
    [onError]
  )

  useEffect(() => {
    ;(async () => {
      const paymentOptions: PaymentOptionsType = {
        ...payment,
        method: 'card',
        onSucceed,
        handleError,
      }
      await PaymentProviderFactory.initPaymentProvider(
        payment.method,
        paymentOptions
      )
    })()
  }, [handleError, onSucceed, payment])

  return (
    <>
      <Field
        label="Name on Card"
        description="Enter the name as it appears on your card"
        control={
          <Input required autoFocus id={PaymentProvider.formIds.NAME_ON_CARD} />
        }
      />
      <Field
        label="Card Number"
        description="Enter the 16-digit card number on the card"
        control={<CardInput required />}
      />
      {payment.method !== 'ezypay' && (
        <Field
          label="CVV Number"
          description="Enter the 3 or 4 digit number on the card"
          control={
            <Input
              name="cvv"
              required
              minLength={3}
              maxLength={4}
              pattern="\d+"
              id={PaymentProvider.formIds.CARD_CCV}
            />
          }
        />
      )}
      <Field
        label="Expiry Date"
        description="Enter the expiration date of the card"
        control={<CardExpiryInput />}
      />
    </>
  )
}

type CreditCardFieldsProps = {
  payment: { method: PaymentProviderType }
  onSucceed: (result: any) => void
  onError: (error: unknown) => void
}

export { CreditCardFields }
export type { CreditCardFieldsProps }
