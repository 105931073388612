import { FC } from 'react'
import { createBooleanDataset } from '../../helpers/others'
import s from './Loading.module.css'

const Loading: FC<LoadingProps> = (props) => {
  const { inline } = props

  return <div {...createBooleanDataset({ inline })} className={s.root} />
}

type LoadingProps = {
  inline?: boolean
}

export { Loading }
export type { LoadingProps }
