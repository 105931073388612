import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { userState } from '../store/user'

const useUser = () => {
  const [user, setUser] = useRecoilState(userState)

  const signIn = useCallback(
    (newValue: typeof user) => {
      return new Promise((resolve) => {
        setUser(newValue)
        /**
         * @todo Find a more elegant way to wait the Recoil states updating.
         */
        setTimeout(resolve, 100)
      })
    },
    [setUser]
  )

  const signOut = useCallback(() => {
    setUser(undefined)
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }, [setUser])

  return {
    user,
    signIn,
    signOut,
  }
}

export { useUser }
