import {
  FC,
  Key,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react'
import { createBooleanDataset } from '../../helpers/others'
import s from './Menu.module.css'

const Menu: FC<MenuProps> = (props) => {
  const { list, selected, onSelect, children } = props

  const [opened, setOpened] = useState(false)

  const createOnItemClick = (key: Item['key']) => () => {
    onSelect?.(key)
  }

  const handleClick = useCallback(() => {
    setOpened((prev) => !prev)
  }, [])

  const handleBackdropClick: MouseEventHandler = useCallback((e) => {
    e.stopPropagation()
    setOpened(false)
  }, [])

  return (
    <div className={s.root} onClick={handleClick}>
      <span className={s.trigger}>{children}</span>
      {opened && (
        <>
          <div className={s.backdrop} onClick={handleBackdropClick}></div>
          <ul className={s.list}>
            {list.map((item) => (
              <li
                key={item.key}
                className={s.item}
                {...createBooleanDataset({
                  selected: item.key === selected,
                  danger: item.danger,
                })}
                onClick={createOnItemClick(item.key)}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

type MenuProps = PropsWithChildren & {
  list: Item[]
  selected?: Item['key']
  onSelect?: (key: Item['key']) => void
}

type Item = {
  key: Key
  title: ReactNode
  danger?: boolean
}

export { Menu }
export type { MenuProps }
