import {
  forwardRef,
  Key,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { createBooleanDataset } from '../../helpers/others'
import { Menu, MenuProps } from '../Menu'
import s from './Tabs.module.css'

const Tabs = forwardRef<TabsRef, TabsProps>((props, ref) => {
  const {
    tabs,
    renderTitle,
    defaultActive = tabs[0].key,
    scrollable = false,
    onChange,
  } = props

  const style = typeof renderTitle === 'undefined' ? 'default' : 'custom'

  const [active, setActive] = useState(defaultActive)

  const createOnTabClick = (key: Tab['key']) => () => {
    if (findTab(key)?.children) {
      return
    }

    setActive(key)
    onChange?.(key)
  }

  const findTab = (key: Tab['key'], targetTabs = tabs): Tab | undefined => {
    for (const tab of targetTabs) {
      if (tab.key === key) {
        return tab
      }

      if (tab.children) {
        return findTab(key, tab.children)
      }
    }
  }

  const handleMenuSelect: Required<MenuProps>['onSelect'] = useCallback(
    (key) => {
      setActive(key)
      onChange?.(key)
    },
    [onChange]
  )

  const activeContent = findTab(active)?.content

  useImperativeHandle(ref, () => ({
    change: setActive,
  }))

  return (
    <div className={s.root} data-style={style}>
      <ul className={s.tabs} {...createBooleanDataset({ scrollable })}>
        {tabs.map((tab) => (
          <li
            key={tab.key}
            className={s.tab}
            data-active={
              (
                tab.children
                  ? findTab(active, tab.children)
                  : active === tab.key
              )
                ? ''
                : undefined
            }
            onClick={createOnTabClick(tab.key)}
          >
            {renderTitle ? (
              renderTitle(tab.key)
            ) : tab.children ? (
              <Menu
                list={tab.children.map((child) => ({
                  key: child.key,
                  title: child.title,
                }))}
                selected={active}
                onSelect={handleMenuSelect}
              >
                {tab.title}
              </Menu>
            ) : (
              tab.title
            )}
          </li>
        ))}
      </ul>
      <div>
        {typeof activeContent === 'function' ? activeContent() : activeContent}
      </div>
    </div>
  )
})

type TabsRef = {
  change: (key: Tab['key']) => void
}

type TabsProps = {
  tabs: Tab[]
  renderTitle?: (key: Tab['key']) => ReactNode
  defaultActive?: Tab['key']
  scrollable?: boolean
  onChange?: (key: Tab['key']) => void
}

type Tab = {
  key: Key
  title?: ReactNode
  content?: ReactNode | (() => ReactNode)
  children?: Tab[]
}

export { Tabs }
export type { TabsRef, TabsProps }
