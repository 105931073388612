const dedup = <Item>(array: Item[], key?: keyof Item): Item[] => {
  if (!key) {
    return [...new Set(array)]
  }

  const deduppedKeys = dedup(array.map((item) => item[key]))
  return deduppedKeys.map(
    (deduppedKey) => array.find((item) => item[key] === deduppedKey)!
  )
}

const createBooleanDataset = (map: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(map).map(([key, value]) => [
      `data-${toHyphen(key)}`,
      value ? '' : undefined,
    ])
  )
}

const toHyphen = (string: string) =>
  string.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)

const isAbsoluteUrl = (uri: any) => {
  try {
    new URL(uri)
    return true
  } catch (error) {
    return false
  }
}

const formatMobile = (countryCode: string, number: string) => {
  const trimmedNumber = number.replace(/^0/, '')
  return trimmedNumber && `${countryCode}${trimmedNumber}`
}

const toCapitalized = (string = '') =>
  string.replace(/\w/, (match) => match.toUpperCase())

export {
  dedup,
  createBooleanDataset,
  isAbsoluteUrl,
  formatMobile,
  toCapitalized,
}
