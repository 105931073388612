import { FC, useCallback, useMemo, useState } from 'react'
import { CardFormatter } from '../../helpers/formatters'
import { PaymentProvider } from '../../third-party/PaymentProvider'
import { Input, InputProps } from '../Input'
import s from './CardInput.module.css'

const CardInput: FC<CardInputProps> = (props) => {
  const [raw, setRaw] = useState('')

  const handleChange: Required<InputProps>['onChange'] = useCallback(
    (value, e) => {
      e.target.value = CardFormatter.format(value)
      setRaw(CardFormatter.getRaw(value))
    },
    []
  )

  const logoName = useMemo(() => {
    if (raw.length < 16) {
      return
    }

    for (const name in numberTests) {
      const regExp = numberTests[name]

      if (regExp.test(raw)) {
        return name
      }
    }
  }, [raw])

  return (
    <div className={s.root}>
      <Input
        name="card"
        pattern={CardFormatter.pattern.source}
        block
        onChange={handleChange}
        {...props}
      />
      {logoName && (
        <img
          src={`/images/${logoName}.png`}
          alt={logoName}
          className={s.logo}
        />
      )}
      <input
        type="hidden"
        value={raw}
        id={PaymentProvider.formIds.CARD_NUMBER}
      />
    </div>
  )
}

const numberTests: Record<string, RegExp> = {
  mastercard: /^2|^5/,
  visa: /^4/,
  'american-express': /^34|^37/,
}

type CardInputProps = Pick<InputProps, 'name' | 'required' | 'autoFocus'> & {}

export { CardInput }
export type { CardInputProps }
