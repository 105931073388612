import { Countries } from '@xoda/common'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Api } from '../../api'
import { formatMobile } from '../../helpers/others'
import { useCountdown } from '../../hooks/countdown'
import { useGet } from '../../hooks/get'
import { useSwitch } from '../../hooks/switch'
import { useUser } from '../../hooks/user'
import { ReactComponent as ArrowLeft1 } from '../../icons/arrow-left-1.svg'
import { configState } from '../../store/config'
import { Amount } from '../Amount'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { Field } from '../Field'
import { Fields, Form, FormProps } from '../Form'
import { Goods, GoodsItem, mapPacks, mapPlans } from '../Goods'
import { Input } from '../Input'
import { Link } from '../Link'
import { Loading } from '../Loading'
import { Modal, ModalRef } from '../Modal'
import { PaymentMethodForm } from '../PaymentMethodForm'
import { PromoCodeField } from '../PromoCodeField'
import { Select } from '../Select'
import { Tc } from '../Tc'
import { BankAccountFieldsProps } from './BankAccountFields'
import { CreditCardFieldsProps } from './CreditCardFields'
import { SignModalsContext } from './SignModals'
import s from './SignUpModal.module.css'

const SignUpModal: FC<SignUpModalProps> = (props) => {
  const context = useContext(SignModalsContext)

  const { onFinish, onCancel } = props

  const config = useRecoilValue(configState)

  const navigate = useNavigate()

  const { data: apiPacks } = useGet<Dto.Pack[]>([
    'member_portal/packs',
    { ...(context.categoryIds && { category_id: context.categoryIds }) },
  ])
  const { data: apiPlans } = useGet<Dto.Plan[]>([
    'member_portal/plans',
    { ...(context.categoryIds && { category_id: context.categoryIds }) },
  ])

  const { signIn } = useUser()
  const {
    value: codeCountdownValue,
    start: starCodeCountdown,
    running: codeCountdownRunning,
  } = useCountdown()
  const { on: opened, close } = useSwitch(true)
  const { on: tcOpened, open: openTc, close: closeTc } = useSwitch()
  const {
    on: paymentTcOpened,
    open: openPaymentTc,
    close: closePaymentTc,
  } = useSwitch()
  const { on: pending, open: startPending, close: stopPending } = useSwitch()

  const [step, setStep] = useState(0)
  const [lead, setLead] = useState<Dto.Lead>()
  const [selectedPackage, setSelectedPackage] = useState<GoodsItem>()
  const [promoCode, setPromoCode] = useState<string>()

  const packages = useMemo(() => {
    const packs = apiPacks && mapPacks(apiPacks)
    const plans = apiPlans && mapPlans(apiPlans)
    return !packs || !plans ? packs || plans : [...packs, ...plans]
  }, [apiPacks, apiPlans])

  const stripeParams = useMemo(() => {
    return new URLSearchParams({
      lead_id: String(lead?.id),
      ...(selectedPackage && {
        [`${selectedPackage.type}_id`]: String(selectedPackage.key),
      }),
      ...(promoCode && {
        promo_code: promoCode,
      }),
    })
  }, [lead?.id, promoCode, selectedPackage])

  const { data: checkout, isValidating: checkoutLoading } =
    useGet<Dto.Checkout>(() => {
      if (!lead) {
        return
      }

      return [
        'member_portal/checkout',
        {
          lead_id: lead.id,
          success_url: String(
            new URL(`/stripe?${stripeParams}`, window.location.origin)
          ),
          cancel_url: String(new URL('/classes', window.location.origin)),
        },
      ]
    })

  const { data: tc } = useGet<Dto.Tc>(() => {
    if (!selectedPackage || selectedPackage?.type === 'product') {
      return
    }

    const tcId = selectedPackage.dto.term_form_id

    if (!tcId) {
      return
    }

    return `member_portal/form/${tcId}`
  })

  const personalFields = useRef<Fields>()
  const codeId = useRef<number>()
  const modal = useRef<ModalRef>(null)

  // The url based gym T&C
  const gymTc = checkout?.terms?.gym
  // The T&C of payment provider
  const paymentTc = checkout?.terms?.payment

  const packageFree = selectedPackage
    ? !selectedPackage.price ||
      (selectedPackage.type === 'plan' && selectedPackage.dto.is_trial)
    : false

  const handleRequestClose = useCallback(() => {
    close()
    onCancel?.()
  }, [close, onCancel])

  const sendCode = useCallback(async () => {
    if (!personalFields.current) {
      return
    }

    codeId.current = (
      await Api.post('verification/codes', {
        type: 'email',
        receiver: personalFields.current.email,
      })
    ).id
    starCodeCountdown()
  }, [starCodeCountdown])

  const go = useCallback((offset = 1) => {
    setStep((prev) => prev + offset)
    modal.current?.toTop()
  }, [])

  const handleBackClick = useCallback(() => {
    if (step === 2) {
      go(-2)
      return
    }

    go(-1)
  }, [go, step])

  const handlePersonSubmit: FormProps['onSubmit'] = useCallback(
    async (fields) => {
      startPending()

      try {
        setLead(
          await Api.post('member_portal/leads', {
            first_name: fields.firstName,
            last_name: fields.lastName,
            email: fields.email,
            mobile: formatMobile(fields.countryCode, fields.mobile),
            source: fields.source,
          })
        )
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }

        return
      } finally {
        stopPending()
      }

      personalFields.current = fields
      await sendCode()
      go()
    },
    [go, sendCode, startPending, stopPending]
  )

  const handleVerifySubmit: FormProps['onSubmit'] = useCallback(
    async (fields) => {
      startPending()

      try {
        await Api.post('verification/check', {
          id: codeId.current,
          code: fields.code,
        })
        go()
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      } finally {
        stopPending()
      }
    },
    [go, startPending, stopPending]
  )

  const handlePackagesNextClick: FormProps['onSubmit'] = useCallback(
    (fields) => {
      setPromoCode(fields['promo-code'])
      go()
    },
    [go]
  )

  const handlePaySucceed: (
    | CreditCardFieldsProps
    | BankAccountFieldsProps
  )['onSucceed'] = useCallback(
    async (payment) => {
      try {
        const body = await Api.post('member_portal/members', {
          lead_id: lead!.id,
          payment,
          ...(tc
            ? { terms: { form_id: tc.id, version: tc.version } }
            : gymTc
            ? { terms: { id: gymTc.id } }
            : undefined),
          ...(selectedPackage && {
            [`${selectedPackage.type}_id`]: selectedPackage.key,
          }),
          promo_code: promoCode,
        })
        await signIn({
          memberId: body.member_id,
          leadId: body.lead_id,
          userId: body.user_id,
          email: personalFields.current!.email,
          token: body.token,
          firstName: body.first_name,
          lastName: body.last_name,
          gymId: Number(body.gym_id),
        })
        go()
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      } finally {
        stopPending()
      }
    },
    [lead, tc, gymTc, selectedPackage, promoCode, signIn, go, stopPending]
  )

  const handleFinishClick = useCallback(() => {
    close()
    onFinish?.()
  }, [close, onFinish])

  useEffect(() => {
    if (step !== 3) {
      return
    }

    if (packageFree) {
      handlePaySucceed({ free: true })
      return
    }

    if (checkout?.payment.method !== 'stripe') {
      return
    }

    if (checkout.payment.redirect_url) {
      window.location.assign(checkout.payment.redirect_url)
      return
    }

    const params = new URLSearchParams({
      ...Object.fromEntries(stripeParams),
      connected_account_id: checkout.payment.stripe_connected_account_id!,
      client_secret: checkout.payment.client_secret!,
      customer_id: checkout.payment.stripe_customer_id!,
    })

    navigate(`/stripe?${params}`)
  }, [checkout, handlePaySucceed, navigate, packageFree, step, stripeParams])

  return (
    <>
      <Modal
        opened={opened}
        closesWithConfirm
        onRequestClose={handleRequestClose}
        ref={modal}
      >
        <div className={s.container}>
          <header className={s.header}>
            {step > 0 && step < steps.length - 1 && (
              <Button variant="text" onClick={handleBackClick}>
                <ArrowLeft1 />
              </Button>
            )}
            <h1 className={s.title}>{steps[step]}</h1>
          </header>
          {step === 0 && (
            <Form onSubmit={handlePersonSubmit}>
              <Field
                label="First Name"
                control={
                  <Input
                    name="firstName"
                    required
                    block
                    autoFocus
                    defaultValue={personalFields.current?.firstName}
                  />
                }
              />
              <Field
                label="Last Name"
                control={
                  <Input
                    name="lastName"
                    required
                    block
                    defaultValue={personalFields.current?.lastName}
                  />
                }
              />
              <Field
                label="Email"
                control={
                  <Input
                    type="email"
                    name="email"
                    required
                    block
                    defaultValue={personalFields.current?.email}
                  />
                }
              />
              <Field
                label="Mobile"
                control={
                  <>
                    <Select
                      name="countryCode"
                      options={Countries.raw.map((country) => ({
                        label: `${country.code} +${country.areaCode}`,
                        value: country.areaCode,
                      }))}
                      required
                      defaultValue={personalFields.current?.countryCode}
                    />
                    <Input
                      type="tel"
                      name="mobile"
                      block
                      defaultValue={personalFields.current?.mobile}
                    />
                  </>
                }
              />
              {config?.sourceOptions && (
                <Field
                  label="How Do You Know About Us?"
                  control={
                    <Select
                      name="source"
                      options={config.sourceOptions.map((option) => ({
                        value: option,
                      }))}
                      block
                      required
                      defaultValue={personalFields.current?.source}
                    />
                  }
                />
              )}
              <Button type="submit" block pending={pending}>
                Next
              </Button>
            </Form>
          )}
          {step === 1 && (
            <Form onSubmit={handleVerifySubmit}>
              <Field
                label="Verification Code"
                control={
                  <>
                    <Input
                      name="code"
                      required
                      pattern="\d{6}"
                      autoFocus
                      autoComplete="off"
                    />
                    {codeCountdownRunning ? (
                      <Button disabled>{codeCountdownValue}</Button>
                    ) : (
                      <Button onClick={sendCode}>Re-send</Button>
                    )}
                  </>
                }
              />
              <Button type="submit" block pending={pending}>
                Next
              </Button>
            </Form>
          )}
          {step === 2 && (
            <>
              {context.skipsPurchase ? (
                <p className={s['skips-purchase-tip']}>
                  Please just skip this step because you are in a purchase
                  process.
                </p>
              ) : (
                packages && (
                  <Goods
                    items={packages}
                    defaultSelected={selectedPackage?.key}
                    onSelect={setSelectedPackage}
                  />
                )
              )}
              <Form onSubmit={handlePackagesNextClick}>
                <PromoCodeField />
                <Button
                  type="submit"
                  block
                  disabled={
                    (!context.skipsPurchase && !selectedPackage) ||
                    checkoutLoading
                  }
                >
                  Next
                </Button>
              </Form>
              {!context.skipsPurchase && (
                <Amount
                  {...(selectedPackage?.type === 'plan' &&
                  selectedPackage.joinFee
                    ? {
                        items: [
                          { name: 'Price', price: selectedPackage.price },
                          { name: 'Join fee', price: selectedPackage.joinFee },
                        ],
                      }
                    : {
                        total: selectedPackage?.price || 0,
                      })}
                />
              )}
            </>
          )}
          {step === 3 &&
            (personalFields.current &&
            checkout &&
            checkout.payment.method !== 'stripe' &&
            !packageFree ? (
              <>
                <PaymentMethodForm
                  lastName={personalFields.current.lastName}
                  payment={checkout.payment}
                  action="Pay Now"
                  pending={pending}
                  tcElement={
                    <>
                      {tc ? (
                        <Field
                          control={
                            <Checkbox name="tc" value={tc.id} required>
                              I’ve read and accepted{' '}
                              <Link target="_blank" onClick={openTc}>
                                Terms & Conditions
                              </Link>
                            </Checkbox>
                          }
                        />
                      ) : (
                        gymTc && (
                          <Field
                            control={
                              <Checkbox name="gymTc" value={gymTc.id} required>
                                I’ve read and accepted{' '}
                                <Link href={gymTc.url} target="_blank">
                                  Terms & Conditions
                                </Link>
                              </Checkbox>
                            }
                          />
                        )
                      )}
                      {paymentTc && (
                        <Field
                          control={
                            <Checkbox
                              name="paymentTc"
                              value={paymentTc.name}
                              required
                            >
                              I’ve read and accepted{' '}
                              {paymentTc.url ? (
                                <Link href={paymentTc.url} target="_blank">
                                  Payment Agreement
                                </Link>
                              ) : (
                                <Link target="_blank" onClick={openPaymentTc}>
                                  Payment Agreement
                                </Link>
                              )}
                            </Checkbox>
                          }
                        />
                      )}
                    </>
                  }
                  onSubmit={startPending}
                  onSucceed={handlePaySucceed}
                  onError={stopPending}
                />
                {!context.skipsPurchase && (
                  <Amount
                    items={[selectedPackage!].map((pack) => ({
                      name:
                        pack.type === 'pack'
                          ? `${pack.name} ×${pack.classesNumber} Classes`
                          : 'Unlimited',
                      price: pack.price,
                    }))}
                  />
                )}
                {checkout?.payment.fees && (
                  <div className={s.footnote}>
                    &#9432; Payment will incur setup fee (
                    {checkout.payment.fees.load_fee}) and transaction fee (
                    {checkout.payment.fees.transaction_fee
                      .map(({ type, value }) => `${type}: ${value}`)
                      .join(', ')}
                    ).
                  </div>
                )}
              </>
            ) : (
              <Loading />
            ))}
          {step === 4 && (
            <>
              <p className={s.welcome}>
                Thank you for signing up with us.
                <br />
                You will receive an email with how you can download and log into
                our app.
                <br />
                We will look forward to seeing you soon.
                <br />
                Please contact us for any further assistance.
              </p>
              <Button block onClick={handleFinishClick}>
                Finish
              </Button>
            </>
          )}
        </div>
      </Modal>
      {tc && (
        <Modal opened={tcOpened} onRequestClose={closeTc}>
          <Tc data={tc} />
        </Modal>
      )}
      {paymentTc && paymentTc.terms_type === 'form' && (
        <Modal opened={paymentTcOpened} onRequestClose={closePaymentTc}>
          <Tc data={paymentTc} />
        </Modal>
      )}
    </>
  )
}

const steps = ['Personal Details', 'Verify Identity', 'Packages', 'Payment', '']

type SignUpModalProps = {
  onFinish?: VoidFunction
  onCancel?: VoidFunction
}

export { SignUpModal }
export type { SignUpModalProps }
