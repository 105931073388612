import { useCallback, useState } from 'react'

const useSwitch = (defaultOn = false) => {
  const [on, setOn] = useState(defaultOn)

  const open = useCallback(() => {
    setOn(true)
  }, [])

  const close = useCallback(() => {
    setOn(false)
  }, [])

  return {
    on,
    open,
    close,
  }
}

export { useSwitch }
