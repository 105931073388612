import { AnchorHTMLAttributes, FC } from 'react'
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom'
import { createBooleanDataset, isAbsoluteUrl } from '../../helpers/others'
import s from './Link.module.css'

const Link: FC<LinkProps> = (props) => {
  const {
    href,
    underline = true,
    bold = false,
    onClick,
    ...passingProps
  } = props

  const baseProps = {
    className: s.root,
    ...createBooleanDataset({ underline, bold }),
    onClick,
    ...passingProps,
  }

  return href && (typeof href !== 'string' || !isAbsoluteUrl(href)) ? (
    <ReactRouterLink to={href} {...baseProps} />
  ) : (
    <a href={href} {...baseProps} />
  )
}

type LinkProps = Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'target' | 'onClick' | 'children'
> & {
  href?: ReactRouterLinkProps['to']
  underline?: boolean
  bold?: boolean
}

export { Link }
export type { LinkProps }
