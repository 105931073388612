import { FC } from 'react'
import { Field } from '../Field'
import { Input } from '../Input'
import s from './PromoCodeField.module.css'

const PromoCodeField: FC = () => {
  return (
    <div className={s.root}>
      <Field
        control={<Input name="promo-code" placeholder="Promo code" size={16} />}
      />
    </div>
  )
}

export { PromoCodeField }
