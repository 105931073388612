import { PaymentOptionsType, PaymentProvider } from './PaymentProvider'

const VAULT_API_ENDPOINT = process.env.REACT_APP_EZYPAY_VAULT_API_ENDPOINT

interface EzypayPaymentOptionsType extends PaymentOptionsType {
  ezypay_access_token: string
  country_code: string
}

class Ezypay extends PaymentProvider {
  private options!: EzypayPaymentOptionsType
  private listenerAdded: boolean = false

  async init(options: EzypayPaymentOptionsType) {
    this.options = options

    if (!this.listenerAdded) {
      const submitButton = document.getElementById(
        PaymentProvider.formIds.SUBMIT_BUTTON
      )
      submitButton?.addEventListener('click', async () => {
        try {
          const result =
            this.options.method === 'card'
              ? await this.createCardPaymentMethodToken()
              : await this.createBankPaymentMethodToken()
          if (!result.paymentMethodToken) {
            throw new Error(result.code || 'Cannot process the payment account')
          }
          this.handleResult(result)
        } catch (err) {
          console.error(err)
          this.options.handleError(err)
        }
      })
      this.listenerAdded = true
    }
  }

  handleResult(result: Result): void {
    const payment = {
      method: this.provider,
      ezypay_payment_method_token: result.paymentMethodToken,
      ezypay_payment_method: this.options.method,
    }
    this.options.onSucceed(payment)
  }

  private async createCardPaymentMethodToken() {
    const accountHolderName = this.getHtmlInputElementValue(
      PaymentProvider.formIds.NAME_ON_CARD
    )
    const accountNumber = this.getHtmlInputElementValue(
      PaymentProvider.formIds.CARD_NUMBER
    )
    const expiryMonth = this.getHtmlInputElementValue(
      PaymentProvider.formIds.CARD_EXPIRY_MONTH
    )
    const expiryYear = this.getHtmlInputElementValue(
      PaymentProvider.formIds.CARD_EXPIRY_YEAR
    )
    const result = await this.callEzypayApi(
      `${VAULT_API_ENDPOINT}/card`,
      this.options.ezypay_access_token!,
      {
        accountHolderName,
        accountNumber,
        expiryMonth,
        expiryYear: expiryYear.slice(-2),
        countryCode: this.options.country_code, // TODO: is it required?
        termAndConditionAgreed: 'true',
      }
    )
    return result
  }

  private async createBankPaymentMethodToken() {
    const accountHolderName = this.getHtmlInputElementValue(
      PaymentProvider.formIds.ACCOUNT_NAME
    )
    const accountNumber = this.getHtmlInputElementValue(
      PaymentProvider.formIds.ACCOUNT_NUMBER
    )
    const bankNumber = this.getHtmlInputElementValue(
      PaymentProvider.formIds.ACCOUNT_BSB
    )
    if (!['AU', 'NZ'].includes(this.options.country_code)) {
      throw new Error(`Invalid country code: ${this.options.country_code}`)
    }

    const countryCode = this.options.country_code
    const result =
      countryCode === 'AU'
        ? await this.callEzypayApi(
            `${VAULT_API_ENDPOINT}/bank`,
            this.options.ezypay_access_token!,
            {
              accountHolderName,
              accountNumber,
              bankNumber,
              countryCode,
              termAndConditionAgreed: 'true',
            }
          )
        : await this.callEzypayApi(
            `${VAULT_API_ENDPOINT}/bank`,
            this.options.ezypay_access_token!,
            {
              accountHolderName,
              accountNumber: accountNumber.slice(0, 7),
              suffixNumber: accountNumber.slice(7),
              bankNumber: bankNumber.slice(0, 2),
              branchNumber: bankNumber.slice(2),
              countryCode,
              termAndConditionAgreed: 'true',
            }
          )
    return result
  }

  private async callEzypayApi(api: string, token: string, body: object) {
    const response = await fetch(api, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return await response.json()
  }

  private getHtmlInputElementValue(
    id: string,
    defaultValue: string = ''
  ): string {
    const element = document.getElementById(id)
    return element instanceof HTMLInputElement ? element.value : defaultValue
  }
}

type Result = {
  paymentMethodToken: string
}

export { Ezypay }
export type { EzypayPaymentOptionsType }
