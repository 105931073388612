import { FC, useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import {
  PaymentOptionsType,
  PaymentProvider,
} from '../../third-party/PaymentProvider'
import { PaymentProviderFactory } from '../../third-party/PaymentProviderFactory'
import { Field } from '../Field'
import { Input } from '../Input'

const BankAccountFields: FC<BankAccountFieldsProps> = (props) => {
  const { payment, onSucceed, onError } = props

  const handleError = useCallback(
    (error: unknown) => {
      toast.error(String(error))
      onError(error)
    },
    [onError]
  )

  useEffect(() => {
    ;(async () => {
      const paymentOptions: PaymentOptionsType = {
        ...payment,
        method: 'bank',
        onSucceed,
        handleError,
      }
      await PaymentProviderFactory.initPaymentProvider(
        payment.method,
        paymentOptions
      )
    })()
  }, [handleError, onSucceed, payment])

  return (
    <>
      <Field
        label="Bank Code / BSB"
        description="Enter max 6-digit number"
        control={
          <Input
            name="bsb"
            required
            maxLength={6}
            pattern="\d+"
            autoFocus
            id={PaymentProvider.formIds.ACCOUNT_BSB}
          />
        }
      />
      <Field
        label="Account Number"
        description="Enter max 9-digit number"
        control={
          <Input
            name="bankAccount"
            required
            maxLength={9}
            pattern="\d+"
            block
            id={PaymentProvider.formIds.ACCOUNT_NUMBER}
          />
        }
      />
      <Field
        label="Account Holder Name"
        description="Enter the account holder's name"
        control={
          <Input
            name="bankAccountName"
            required
            id={PaymentProvider.formIds.ACCOUNT_NAME}
          />
        }
      />
    </>
  )
}

type BankAccountFieldsProps = {
  payment: { method: 'ezidebit' | 'ezypay' | 'paychoice' | 'stripe' }
  onSucceed: (result: any) => void
  onError: (error: unknown) => void
}

export { BankAccountFields }
export type { BankAccountFieldsProps }
