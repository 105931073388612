import { atom } from 'recoil'

const userState = atom<User | undefined>({
  key: 'user',
  default: undefined,
  effects: [
    ({ setSelf, onSet }) => {
      const key = 'user'

      const stored = sessionStorage.getItem(key)

      if (stored) {
        setSelf(JSON.parse(stored))
      }

      onSet((value) => {
        if (!value) {
          sessionStorage.removeItem(key)
          return
        }

        sessionStorage.setItem(key, JSON.stringify(value))
      })
    },
  ],
})

type User = {
  memberId: number
  leadId: number
  userId: number
  email: string
  token: string
  firstName: string
  lastName: string
  gymId: number
}

export type { User }
export { userState }
