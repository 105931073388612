import dayjs, { Dayjs } from 'dayjs'
import moment from 'moment-timezone'

const toStandardDateString = (
  value?: ConstructorParameters<typeof Date>[0] | Dayjs
) => (value ? dayjs(value).format('YYYY-MM-DD') : '')

/**
 * The default time zone value was set earlier.
 */
const toTimeZoneString = (utcValue: string) => {
  return moment(utcValue).format('YYYY-MM-DD HH:mm:ss')
}

export { toStandardDateString, toTimeZoneString }
