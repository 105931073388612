import {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import { toStandardDateString } from '../../helpers/date'
import { ReactComponent as Calendar1 } from '../../icons/calendar-1.svg'
import s from './DatePicker.module.css'

const DatePicker = forwardRef<DatePickerRef, DatePickerProps>((props, ref) => {
  const { min, max, onChange } = props

  const control = useRef<HTMLInputElement>(null)

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange?.(e.currentTarget.value)
    },
    [onChange]
  )

  useImperativeHandle(ref, () => ({
    change: (value) => {
      if (!control.current) {
        return
      }

      control.current.value = toStandardDateString(value)
    },
  }))

  return (
    <label className={s.root}>
      <input
        type="date"
        required
        min={toStandardDateString(min)}
        max={toStandardDateString(max)}
        onChange={handleChange}
        ref={control}
      />
      <Calendar1 className={s['selected-day-icon']} />
    </label>
  )
})

type DatePickerRef = {
  change: (value: Parameters<typeof toStandardDateString>[0]) => void
}

type DatePickerProps = {
  min?: Parameters<typeof toStandardDateString>[0]
  max?: Parameters<typeof toStandardDateString>[0]
  onChange?: (value: string) => void
}

export { DatePicker }
export type { DatePickerRef, DatePickerProps }
