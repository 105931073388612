import { FC, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { Api } from '../../api'
import { useSign } from '../../hooks/sign'
import { useUser } from '../../hooks/user'
import { ReactComponent as ArrowDown1 } from '../../icons/arrow-down-1.svg'
import { ReactComponent as ArrowRight1 } from '../../icons/arrow-right-1.svg'
import { Link } from '../Link'
import { Menu, MenuProps } from '../Menu'
import s from './Header.module.css'

const Header: FC = () => {
  const { user, signOut } = useUser()
  const sign = useSign()

  const handleAccountMenuSelect: Required<MenuProps>['onSelect'] = useCallback(
    async (key) => {
      switch (key) {
        case 'delete-account':
          if (
            !window.confirm(
              'Are you sure you want to delete your account and all your data? This cannot be undone.'
            )
          ) {
            return
          }

          await Api.post('users/delete_user', undefined, { memberAuth: true })
          signOut()
          return

        case 'sign-out':
          signOut()
          return

        default:
          throw new Error(`Unknown action "${key}".`)
      }
    },
    [signOut]
  )

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Nunito"
        />
      </Helmet>
      <header className={s.header}>
        <div className={s['header-content']}>
          <h1 className={s.title}>Your next class is waiting for you.</h1>
          <div className={s.account}>
            {user ? (
              <>
                Hi,
                <Menu
                  list={[
                    {
                      key: 'delete-account',
                      title: 'Delete account',
                      danger: true,
                    },
                    { key: 'sign-out', title: 'Logout' },
                  ]}
                  onSelect={handleAccountMenuSelect}
                >
                  {user.firstName}
                  <ArrowDown1 />
                </Menu>
              </>
            ) : (
              <>
                <span>
                  Login to see your <Link onClick={sign.start}>schedule</Link>
                </span>
                <ArrowRight1 />
              </>
            )}
          </div>
        </div>
      </header>
      {sign.element}
    </>
  )
}

export { Header }
