import { FC } from 'react'
import s from './Tc.module.css'

const Tc: FC<TcProps> = (props) => {
  const { data } = props

  return <article className={s.root}>{data.task_data.map(renderLine)}</article>
}

const renderLine = (line: TcProps['data']['task_data'][number]) => {
  const Tag = tags[line.element] || 'p'
  return (
    <Tag key={line.id} dangerouslySetInnerHTML={{ __html: line.content }} />
  )
}

const tags = {
  Header: 'h1',
  'Header Text': 'h1',
  Paragraph: 'p',
} as const

type TcProps = {
  data: Dto.Tc
}

export { Tc }
export type { TcProps }
