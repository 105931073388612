import { Ezidebit, EzidebitPaymentOptionsType } from './Ezidebit'
import { Ezypay, EzypayPaymentOptionsType } from './Ezypay'
import { PaymentOptionsType, PaymentProviderType } from './PaymentProvider'

class PaymentProviderFactory {
  static ezidebit: Ezidebit
  static ezypay: Ezypay

  static getPaymentProvider(provider: PaymentProviderType) {
    switch (provider) {
      case 'ezidebit':
        if (!PaymentProviderFactory.ezidebit)
          PaymentProviderFactory.ezidebit = new Ezidebit(provider)
        return PaymentProviderFactory.ezidebit
      case 'ezypay':
        if (!PaymentProviderFactory.ezypay)
          PaymentProviderFactory.ezypay = new Ezypay(provider)
        return PaymentProviderFactory.ezypay
      default:
        throw new Error(`Unsupported payment provider: ${provider}`)
    }
  }

  static async initPaymentProvider(
    provider: PaymentProviderType,
    options: PaymentOptionsType
  ) {
    const paymentProvider = PaymentProviderFactory.getPaymentProvider(provider)
    if (paymentProvider instanceof Ezidebit) {
      await paymentProvider.init(options as EzidebitPaymentOptionsType)
    } else if (paymentProvider instanceof Ezypay) {
      await paymentProvider.init(options as EzypayPaymentOptionsType)
    }
  }
}

export { PaymentProviderFactory }
