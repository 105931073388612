import { useEffect, useMemo, useRef } from 'react'
import toast from 'react-hot-toast'
import { useRecoilState } from 'recoil'
import { Api } from '../api'
import {
  getGoodsType,
  GoodsItem,
  isPack,
  isPlan,
  isProduct,
} from '../components/Goods'
import { Modal } from '../components/Modal'
import {
  PaymentMethodForm,
  PaymentMethodFormProps,
} from '../components/PaymentMethodForm'
import { gymState } from '../store/gym'
import { useGet } from './get'
import { useSwitch } from './switch'
import { useUser } from './user'

const usePurchase = (item?: GoodsItem['dto']) => {
  const { user } = useUser()
  const [gym] = useRecoilState(gymState)
  const paymentMethodForm = useSwitch()
  const pending = useSwitch()
  const paymentMethodPending = useSwitch()

  const resolvePromise =
    useRef<Parameters<ConstructorParameters<typeof Promise>[0]>[0]>()

  const stripeParams = useMemo(() => {
    if (!user) {
      return
    }

    return new URLSearchParams({
      lead_id: String(user.leadId),
      ...(item && {
        [`${getGoodsType(item)}_id`]: String(item.id),
      }),
    })
  }, [user, item])

  const { data: checkout } = useGet<Dto.Checkout>(() => {
    if (!(paymentMethodForm.on && user?.leadId)) {
      return
    }

    return [
      'member_portal/checkout',
      {
        lead_id: user.leadId,
        success_url: String(
          new URL(`/stripe?${stripeParams || ''}`, window.location.origin)
        ),
        cancel_url: String(new URL('/classes', window.location.origin)),
      },
    ]
  })

  const submit = (extraPayload?: Parameters<typeof request>[0]) =>
    new Promise(async (resolve, reject) => {
      pending.open()

      try {
        await request(extraPayload)
        resolve(undefined)
      } catch (error) {
        if (
          error instanceof Error &&
          error.message.includes('payment method has not yet been added')
        ) {
          resolvePromise.current = resolve
          toast.loading(error.message)
          paymentMethodForm.open()
        } else {
          reject(error)
        }
      } finally {
        pending.close()
      }
    })

  const request = async (extraPayload?: Record<string, unknown>) => {
    if (!(user && gym && item)) {
      return
    }

    await Api.post(
      'purchases',
      {
        gym_id: user.gymId,
        user_id: user.userId,
        payment_method: (item.gym || gym).payment_method,
        pos_items: isProduct(item)
          ? [
              {
                type: 'product',
                type_id: item.id,
                name: item.name,
                quantity: 1,
                price: item.price,
              },
            ]
          : [],
        ...(isPlan(item) && {
          gym_plan_id: item.id,
          gym_plan_payment_schedule: item.payment_schedule || 'weekly',
          gym_plan_price: false,
        }),
        ...(isPack(item) && {
          gym_packs: [{ id: item.id, price: false }],
        }),
        ...extraPayload,
      },
      { memberAuth: true }
    )
  }

  const handleSucceed: PaymentMethodFormProps['onSucceed'] = async (result) => {
    try {
      await Api.put('member_portal/members/payment_method', result, {
        memberAuth: true,
      })
      await request()
      paymentMethodForm.close()
      resolvePromise.current?.(undefined)
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message)
      }
    } finally {
      paymentMethodPending.close()
    }
  }

  const handleError = (error: unknown) => {
    paymentMethodPending.close()
    toast.error(String(error))
  }

  const element = user && checkout && (
    <Modal
      opened={paymentMethodForm.on}
      onRequestClose={paymentMethodForm.close}
    >
      <PaymentMethodForm
        lastName={user.lastName}
        payment={checkout.payment}
        pending={paymentMethodPending.on}
        onSubmit={paymentMethodPending.open}
        onSucceed={handleSucceed}
        onError={handleError}
      />
    </Modal>
  )

  useEffect(() => {
    if (!!checkout) {
      toast.dismiss()
    }
  }, [checkout])

  return {
    submit,
    element,
    pending: pending.on,
  }
}

export { usePurchase }
