import { FC, InputHTMLAttributes, PropsWithChildren } from 'react'
import s from './Checkbox.module.css'

const Checkbox: FC<CheckboxProps> = (props) => {
  const { children, ...passingProps } = props

  return (
    <label className={s.root}>
      <input type="checkbox" className={s.control} {...passingProps} />
      <span>{children}</span>
    </label>
  )
}

type CheckboxProps = PropsWithChildren &
  Pick<
    InputHTMLAttributes<HTMLInputElement>,
    'name' | 'value' | 'required'
  > & {}

export { Checkbox }
export type { CheckboxProps }
