import { useCallback, useRef } from 'react'
import {
  SignModals,
  SignModalsContext,
  SignModalsContextValue,
} from '../components/SignModals'
import { useSwitch } from './switch'

const useSign = (context?: SignModalsContextValue) => {
  const { on, open, close } = useSwitch()

  const resolveRef = useRef<Function>()

  const start = useCallback(() => {
    return new Promise<never>((resolve) => {
      resolveRef.current = resolve
      open()
    })
  }, [open])

  const handleFinish = useCallback(() => {
    close()
    resolveRef.current?.()
  }, [close])

  const element = on ? (
    <SignModalsContext.Provider value={context || {}}>
      <SignModals
        defaultType="sign-in"
        onFinish={handleFinish}
        onCancel={close}
      />
    </SignModalsContext.Provider>
  ) : null

  return {
    element,
    start,
  }
}

export { useSign }
