import { FC, FormEventHandler, PropsWithChildren, useCallback } from 'react'
import s from './Form.module.css'

const Form: FC<FormProps> = (props) => {
  const { onSubmit, children } = props

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      const form = e.target as HTMLFormElement
      const fields = [...form.elements].reduce((pool, control) => {
        if (
          control instanceof HTMLInputElement ||
          control instanceof HTMLSelectElement
        ) {
          return {
            ...pool,
            ...(control.name && { [control.name]: control.value }),
          }
        }

        return pool
      }, {} as Fields)
      onSubmit(fields)
    },
    [onSubmit]
  )

  return (
    <form className={s.root} onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

type FormProps = PropsWithChildren & {
  onSubmit: (fields: Fields) => void
}

type Fields = Record<string, string>

export { Form }
export type { FormProps, Fields }
