import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useCallback,
} from 'react'
import { createBooleanDataset } from '../../helpers/others'
import s from './Input.module.css'

const Input: FC<InputProps> = (props) => {
  const { multiline = false, block = false, onChange, ...passingProps } = props

  const handleChange: Required<PropsPool>['onChange'] = useCallback(
    (e) => {
      onChange?.(e.target.value, e)
    },
    [onChange]
  )

  const Component = multiline ? 'textarea' : 'input'

  return (
    <Component
      className={s.root}
      {...createBooleanDataset({ block })}
      onChange={handleChange}
      {...passingProps}
    />
  )
}

type InputProps = Pick<
  PropsPool,
  | 'type'
  | 'name'
  | 'id'
  | 'defaultValue'
  | 'required'
  | 'minLength'
  | 'maxLength'
  | 'pattern'
  | 'size'
  | 'rows'
  | 'placeholder'
  | 'autoFocus'
  | 'autoComplete'
> & {
  multiline?: boolean
  block?: boolean
  onChange?: (
    value: string,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

type PropsPool = InputHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement>

export { Input }
export type { InputProps }
