import { FC } from 'react'
import { PaymentProvider } from '../../third-party/PaymentProvider'
import { Button } from '../Button'
import { Form } from '../Form'
import {
  BankAccountFields,
  BankAccountFieldsProps,
} from '../SignModals/BankAccountFields'
import {
  CreditCardFields,
  CreditCardFieldsProps,
} from '../SignModals/CreditCardFields'
import { Tabs } from '../Tabs'

const PaymentMethodForm: FC<PaymentMethodFormProps> = ({
  lastName,
  action = 'Save',
  pending = false,
  tcElement,
  onSubmit,
  ...passingProps
}) => {
  const { payment } = passingProps

  const handleSubmit = () => {
    // Trigger payment manually.
    document.getElementById(PaymentProvider.formIds.SUBMIT_BUTTON)?.click()
    onSubmit?.()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Tabs
        tabs={[
          {
            key: 'card',
            title: 'Credit Card',
            content: () => <CreditCardFields {...passingProps} />,
          },
          {
            key: 'bank',
            title: 'Bank Account',
            content: () => <BankAccountFields {...passingProps} />,
          },
        ]}
      />
      {tcElement}
      <input
        type="hidden"
        value={lastName}
        id={PaymentProvider.formIds.CUSTOMER_LAST_NAME}
      />
      <input
        type="hidden"
        value={payment.customer_reference}
        id={PaymentProvider.formIds.CUSTOMER_REFERENCE}
      />
      <Button type="submit" block pending={pending}>
        {action}
      </Button>
      <button
        type="button"
        hidden
        id={PaymentProvider.formIds.SUBMIT_BUTTON}
      ></button>
    </Form>
  )
}

type PaymentMethodFormProps = {
  lastName: string
  action?: string
  pending?: boolean
  tcElement?: React.ReactNode
  onSubmit?: VoidFunction
} & Pick<Dto.Checkout, 'payment'> &
  (CreditCardFieldsProps | BankAccountFieldsProps)

export { PaymentMethodForm }
export type { PaymentMethodFormProps }
