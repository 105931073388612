import { atom } from 'recoil'

const gymState = atom<Dto.Gym | undefined>({
  key: 'gym',
  default: undefined,
  effects: [
    ({ setSelf, onSet }) => {
      const key = 'gym'

      const stored = sessionStorage.getItem(key)

      if (stored) {
        setSelf(JSON.parse(stored))
      }

      onSet((value) => {
        if (!value) {
          sessionStorage.removeItem(key)
          return
        }

        sessionStorage.setItem(key, JSON.stringify(value))
      })
    },
  ],
})

export { gymState }
