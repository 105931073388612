import { PaymentOptionsType, PaymentProvider } from './PaymentProvider'

const SDK_ID = 'ezidebit-sdk'

interface EzidebitPaymentOptionsType extends PaymentOptionsType {
  customer_reference: string
  ezidebit_public_key: string
}

class Ezidebit extends PaymentProvider {
  private options!: EzidebitPaymentOptionsType

  init(options: EzidebitPaymentOptionsType): Promise<void> {
    this.options = options
    return new Promise((resolve) => {
      const existed = document.getElementById(SDK_ID)
      if (existed) {
        existed.remove()
      }

      const script = document.createElement('script')
      script.addEventListener('load', () => {
        eziDebit.init(
          this.options.ezidebit_public_key,
          this.options.method === 'card'
            ? {
                submitAction: 'SaveCustomer',
                submitButton: PaymentProvider.formIds.SUBMIT_BUTTON,
                submitCallback: this.handleResult.bind(this),
                submitError: this.options.handleError,
                customerLastName: PaymentProvider.formIds.CUSTOMER_LAST_NAME,
                customerReference: PaymentProvider.formIds.CUSTOMER_REFERENCE,
                nameOnCard: PaymentProvider.formIds.NAME_ON_CARD,
                cardNumber: PaymentProvider.formIds.CARD_NUMBER,
                cardExpiryMonth: PaymentProvider.formIds.CARD_EXPIRY_MONTH,
                cardExpiryYear: PaymentProvider.formIds.CARD_EXPIRY_YEAR,
                cardCCV: PaymentProvider.formIds.CARD_CCV,
              }
            : {
                submitAction: 'SaveCustomerAccount',
                submitButton: PaymentProvider.formIds.SUBMIT_BUTTON,
                submitCallback: this.handleResult.bind(this),
                submitError: this.options.handleError,
                customerLastName: PaymentProvider.formIds.CUSTOMER_LAST_NAME,
                customerReference: PaymentProvider.formIds.CUSTOMER_REFERENCE,
                accountName: PaymentProvider.formIds.ACCOUNT_NAME,
                accountBSB: PaymentProvider.formIds.ACCOUNT_BSB,
                accountNumber: PaymentProvider.formIds.ACCOUNT_NUMBER,
              },
          process.env.REACT_APP_EZIDEBIT_ENDPOINT!
        )
        resolve()
      })
      script.id = SDK_ID
      script.src =
        'https://static.ezidebit.com.au/javascriptapi/js/ezidebit_2_0_0.min.js'
      document.head.append(script)
    })
  }

  handleResult(result: Result): void {
    const payment = {
      method: this.provider,
      ezidebit_customer_id: String(result.Data.CustomerRef),
      ezidebit_customer_reference: this.options.customer_reference,
      ezidebit_payment_method: this.options.method,
    }
    this.options.onSucceed(payment)
  }
}

declare const eziDebit: {
  init: (
    publicKey: string,
    options:
      | {
          submitAction: 'SaveCustomer'
          submitButton: string
          submitCallback: Function
          submitError: Function
          customerFirstName?: string
          customerLastName: string
          customerReference?: string
          customerAddress1?: string
          customerAddress2?: string
          customerSuburb?: string
          customerState?: string
          customerPostcode?: string
          customerEmail?: string
          customerMobile?: string
          cardNumber: string
          nameOnCard?: string
          cardExpiryMonth: string
          cardExpiryYear: string
          cardCCV: string
          paymentAmount?: string
          paymentReference?: string
        }
      | {
          submitAction: 'SaveCustomerAccount'
          submitButton: string
          submitCallback: Function
          submitError: Function
          customerFirstName?: string
          customerLastName: string
          customerReference?: string
          customerAddress1?: string
          customerAddress2?: string
          customerSuburb?: string
          customerState?: string
          customerPostcode?: string
          customerEmail?: string
          customerMobile?: string
          accountName: string
          accountBSB: string
          accountNumber: string
        },
    endpoint: string
  ) => void
}

type Result = {
  Data: {
    BankReceiptID: null
    CustomerRef: number
  }
  Error: number
  ErrorMessage: null
}

export { Ezidebit }
export type { EzidebitPaymentOptionsType }
