import { FC, PropsWithChildren } from 'react'
import s from './Tag.module.css'

const Tag: FC<TagProps> = (props) => {
  const { casing, children } = props

  return (
    <span className={s.root} data-casing={casing}>
      {children}
    </span>
  )
}

type TagProps = PropsWithChildren & {
  casing?: 'upper'
}

export { Tag }
export type { TagProps }
