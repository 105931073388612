import { FC, PropsWithChildren } from 'react'
import s from './AsideSection.module.css'

const AsideSection: FC<AsideSectionProps> = (props) => {
  const { title, description, children } = props

  return (
    <section className={s.root}>
      <header className={s.header}>
        <h1 className={s.title}>{title}</h1>
        <div>{description}</div>
      </header>
      {children}
    </section>
  )
}

type AsideSectionProps = PropsWithChildren & {
  title: string
  description: string
}

export { AsideSection }
export type { AsideSectionProps }
