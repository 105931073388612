import jwtDecode from 'jwt-decode'
import toast from 'react-hot-toast'
import { atom, selector } from 'recoil'

const touristTokenState = atom<string | undefined>({
  key: 'tourist-token',
  default: undefined,
  effects: [
    ({ setSelf, onSet }) => {
      const key = 'tourist-token'

      const stored = sessionStorage.getItem(key)

      if (stored) {
        setSelf(stored)
      }

      onSet((value) => {
        if (!value) {
          sessionStorage.removeItem(key)
          return
        }

        sessionStorage.setItem(key, value)
      })
    },
  ],
})

const gymIdState = selector({
  key: 'gym-id',

  get: ({ get }) => {
    const token = get(touristTokenState)

    if (!token) {
      return
    }

    try {
      return Number(jwtDecode<DecodedToken>(token).gymId)
    } catch (error) {
      toast.error(`Invalid token \`${token}\``)
      return
    }
  },
})

type DecodedToken = {
  gymId: string
}

export { touristTokenState, gymIdState }
