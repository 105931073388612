import { snapshot_UNSTABLE } from 'recoil'
import { dedup, isAbsoluteUrl } from '../helpers/others'
import { gymIdState, touristTokenState } from '../store/tourist'
import { userState } from '../store/user'

class Api {
  static async get(uri: string, params: Data, config?: Config) {
    const response = await fetch(
      this.#getUrl(uri, { gym_id: this.#gymId, ...params }),
      {
        headers: {
          ...this.#getHeaders(config),
        },
      }
    )
    return await this.#getBody(response)
  }

  static #createSend =
    <Type extends Record<string, unknown>>(method: 'POST' | 'PUT') =>
    async <Uri extends keyof Type>(uri: Uri, body?: Data, config?: Config) => {
      const response = await fetch(this.#getUrl(String(uri)), {
        method,
        headers: {
          ...this.#getHeaders(config),
          'content-type': 'application/json',
        },
        body: JSON.stringify({ gym_id: this.#gymId, ...body }),
      })
      return await this.#getBody<Type[Uri]>(response)
    }

  static post = this.#createSend<Dto.Post>('POST')

  static put = this.#createSend<Dto.Put>('PUT')

  static #getHeaders(config?: Config) {
    return {
      authorization: `Bearer ${
        config?.memberAuth ? this.#user?.token : this.#touristToken
      }`,
      utc_offset: String(new Date().getTimezoneOffset()),
      gym_id: this.#gymId,
    }
  }

  static get #user() {
    return snapshot_UNSTABLE().getLoadable(userState).contents
  }

  static get #touristToken() {
    return snapshot_UNSTABLE().getLoadable(touristTokenState).contents
  }

  static get #gymId() {
    return (
      this.#user?.gymId || snapshot_UNSTABLE().getLoadable(gymIdState).contents
    )
  }

  static #getUrl(uri: string, params?: Data) {
    const url = new URL(
      isAbsoluteUrl(uri) ? uri : `${process.env.REACT_APP_API_BASE_URL}/${uri}`
    )

    if (!params) {
      return url
    }

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        dedup(value).forEach((item) => {
          url.searchParams.append(key, item)
        })
        return
      }

      url.searchParams.append(key, String(value))
    })
    return url
  }

  static async #getBody<Body>(response: Response) {
    const body = await response.json()

    if (body.error) {
      throw new ApiError(
        { status: response.status },
        body.message || body.error
      )
    }

    if (body.errors) {
      const error = body.errors[0]
      throw new ApiError(
        { status: response.status },
        `"${error.msg}" caused by \`${error.param}\``
      )
    }

    if (!response.ok) {
      throw new ApiError({ status: response.status }, body.message)
    }

    return body as Body
  }
}

class ApiError extends Error {
  status: Response['status']

  constructor(
    info: {
      status: ApiError['status']
    },
    ...passingArgs: Parameters<typeof Error>
  ) {
    super(...passingArgs)
    this.status = info.status
  }
}

type Data = Record<string, unknown>

type Config = {
  memberAuth?: boolean
}

export { Api, ApiError }
