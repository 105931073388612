import dayjs from 'dayjs'
import {
  FC,
  Key,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { Api } from '../../api'
import { AsideSection } from '../../components/AsideSection'
import {
  BookingClasses,
  BookingClassItem,
  mapBookingClasses,
} from '../../components/BookingClasses'
import { Button } from '../../components/Button'
import {
  DatePicker,
  DatePickerProps,
  DatePickerRef,
} from '../../components/DatePicker'
import { Header } from '../../components/Header'
import { Loading } from '../../components/Loading'
import {
  mapMarketClasses,
  MarketClasses,
  MarketClassesProps,
  MarketClassItem,
} from '../../components/MarketClasses'
import { Tabs, TabsProps, TabsRef } from '../../components/Tabs'
import { toStandardDateString } from '../../helpers/date'
import { dedup } from '../../helpers/others'
import { useGet } from '../../hooks/get'
import { useSign } from '../../hooks/sign'
import { useUser } from '../../hooks/user'
import s from './Classes.module.css'

const Classes: FC = () => {
  const { user } = useUser()

  const [selectedDay, setSelectedDay] = useState<Key>(
    toStandardDateString(new Date())
  )
  const [style, setStyle] = useState<Key>('')
  const [cartClassKeys, setCartClassKeys] = useState<
    Required<MarketClassesProps>['selectedItems']
  >(new Set())
  const [sessions, setSessions] = useState<Dto.Session[]>()

  const datePicker = useRef<DatePickerRef>(null)
  const daysTabs = useRef<TabsRef>(null)

  const { data: weekSessions, mutate: mutateWeekSessions } = useGet<
    Dto.Session[]
  >([
    'member_portal/sessions',
    {
      date: toStandardDateString(selectedDay),
      range: 'week',
      ...(user && { member_id: user.memberId }),
    },
  ])

  const sign = useSign({
    ...(sessions && {
      categoryIds: [...cartClassKeys].map(
        (key) => sessions.find((session) => session.id === key)!.category.id
      ),
    }),
  })

  const startDay = dayjs().day(0)

  const weekDays = useMemo(() => {
    const startWeekDay = dayjs(selectedDay).day(0)
    return Array.from({ length: 7 }, (item, index) =>
      startWeekDay.add(index, 'day')
    )
  }, [selectedDay])

  const marketClasses: MarketClassItem[] | undefined = useMemo(() => {
    if (!sessions) {
      return
    }

    return mapMarketClasses(sessions)
  }, [sessions])

  const shownMarketClasses: MarketClassItem[] | undefined = useMemo(() => {
    if (!marketClasses) {
      return
    }

    return marketClasses.filter(
      (item) =>
        dayjs(item.startTime).isSame(selectedDay, 'date') &&
        (!style || item.name === style)
    )
  }, [marketClasses, selectedDay, style])

  const cartClasses: BookingClassItem[] | undefined = useMemo(() => {
    if (!marketClasses) {
      return []
    }

    return marketClasses
      .filter((item) => cartClassKeys.has(item.key))
      .map((item) => ({
        key: item.key,
        name: item.name,
        staff: item.staff.name,
        startTime: item.startTime,
        endTime: item.endTime,
      }))
  }, [marketClasses, cartClassKeys])

  const { comingClasses, pastClasses } = useMemo(() => {
    const now = dayjs()
    return {
      comingClasses: findBookedClasses(weekSessions || []).filter(
        (item) => dayjs(item.startTime) > now
      ),
      pastClasses: findBookedClasses(sessions || []).filter(
        (item) => dayjs(item.startTime) < now
      ),
    }
  }, [sessions, weekSessions])

  const handleDatePickerChange: Required<DatePickerProps>['onChange'] =
    useCallback((value) => {
      value = value || toStandardDateString(Date.now())
      setSelectedDay(toStandardDateString(value))
      daysTabs.current?.change(value)
    }, [])

  const handleDaysTabsChange: Required<TabsProps>['onChange'] = useCallback(
    (key) => {
      const value = toStandardDateString(key)
      setSelectedDay(value)
      datePicker.current?.change(value)
    },
    []
  )

  const handleMarketClassClick: Required<MarketClassesProps>['onClickItem'] =
    useCallback((key) => {
      setCartClassKeys((prev) => {
        const next = new Set(prev)
        next[next.has(key) ? 'delete' : 'add'](key)
        return next
      })
    }, [])

  const handleBookClick = async () => {
    if (!user) {
      await sign.start()
    }

    try {
      await Promise.all(
        [...cartClassKeys].map((key) =>
          Api.post('bookings', { session_id: key }, { memberAuth: true })
        )
      )
      mutateWeekSessions()
      setCartClassKeys(new Set())
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message)
      }
    }
  }

  useEffect(() => {
    setSessions((prev) =>
      dedup([...(weekSessions || []), ...(prev || [])], 'id')
    )
  }, [weekSessions])

  return (
    <div className={s.root}>
      <Helmet>
        <title>Classes</title>
      </Helmet>
      <Header />
      <main className={s.main}>
        <div className={s.market}>
          <div className={s.days}>
            <div className={s['selected-day']}>
              <DatePicker
                min={startDay}
                max={startDay.add(7 * 4 - 1, 'day')}
                onChange={handleDatePickerChange}
                ref={datePicker}
              />
              {dayjs(selectedDay).format('dddd, D MMMM YYYY')}
            </div>
            <Tabs
              tabs={weekDays.map((day) => ({
                key: toStandardDateString(day),
              }))}
              renderTitle={(key) => (
                <div className={s.day}>
                  <div>{dayjs(key).format('ddd')}</div>
                  <div className={s['day-date']}>{dayjs(key).date()}</div>
                </div>
              )}
              defaultActive={selectedDay}
              onChange={handleDaysTabsChange}
              ref={daysTabs}
            />
          </div>
          <Tabs
            tabs={[
              { key: '', title: 'All classes' },
              {
                key: 'filter',
                title: 'Class style',
                children:
                  marketClasses &&
                  dedup(marketClasses.map((item) => item.name)).map((name) => ({
                    key: name,
                    title: name,
                  })),
              },
            ]}
            onChange={setStyle}
          />
          {shownMarketClasses ? (
            <MarketClasses
              items={shownMarketClasses}
              selectedItems={cartClassKeys}
              onClickItem={handleMarketClassClick}
            />
          ) : (
            <Loading />
          )}
        </div>
        <div className={s.divider} />
        <aside className={s.my}>
          <AsideSection
            title={`Bookings ${cartClasses ? `(${cartClasses.length})` : ''}`}
            description={
              cartClasses?.length
                ? `You have selected ${cartClasses.length} classes`
                : 'Classes that you book will be added here'
            }
          >
            <BookingClasses items={cartClasses} />
            {!!cartClasses.length && (
              <Button color="black" block onClick={handleBookClick}>
                Book
              </Button>
            )}
          </AsideSection>
          {user && (
            <>
              <AsideSection
                title="Your Schedule"
                description={`${comingClasses.length} Classes booked for this week`}
              >
                <BookingClasses items={comingClasses} />
              </AsideSection>
              <AsideSection
                title="Past Classes"
                description="Review your classes"
              >
                <BookingClasses items={pastClasses} />
              </AsideSection>
            </>
          )}
        </aside>
      </main>
      {sign.element}
    </div>
  )
}

const findBookedClasses = (sessions: Dto.Session[]) =>
  mapBookingClasses(sessions?.filter(amIBooked))

const amIBooked = (session: Dto.Session) => session.has_booked

export { Classes }
