import { FC, ReactNode } from 'react'
import s from './Field.module.css'

const Field: FC<FieldProps> = (props) => {
  const { label, description, control } = props

  return (
    <label className={s.root}>
      {label && <div className={s.label}>{label}</div>}
      {description && <div className={s.description}>{description}</div>}
      <div className={s.controls}>{control}</div>
    </label>
  )
}

type FieldProps = {
  label?: string
  description?: string
  control: ReactNode
}

export { Field }
export type { FieldProps }
