import clsx from 'clsx'
import {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import { useRecoilValue } from 'recoil'
import { configState } from '../../store/config'
import { Button } from '../Button'
import s from './Modal.module.css'

const Modal = forwardRef<ModalRef, ModalProps>((props, ref) => {
  const { opened, closesWithConfirm = false, onRequestClose, children } = props

  const config = useRecoilValue(configState)

  const main = useRef<HTMLElement>(null)

  useImperativeHandle(ref, () => ({
    toTop: () => {
      main.current?.scrollTo(0, 0)
    },
  }))

  const handleCloserClick = useCallback(() => {
    if (closesWithConfirm) {
      if (window.confirm('Sure to leave? Your work will not be saved.')) {
        onRequestClose?.()
      }

      return
    }

    onRequestClose?.()
  }, [closesWithConfirm, onRequestClose])

  if (!opened) {
    return null
  }

  return (
    <div className={s.root}>
      <div className={s.backdrop} />
      <section className={s.main} ref={main}>
        <div
          className={clsx(
            s.content,
            config?.modalColorScheme === 'light' && s.light
          )}
        >
          {children}
        </div>
        <Button variant="text" className={s.closer} onClick={handleCloserClick}>
          ⨉
        </Button>
      </section>
    </div>
  )
})

type ModalProps = PropsWithChildren & {
  opened: boolean
  closesWithConfirm?: boolean
  onRequestClose?: VoidFunction
}

type ModalRef = {
  toTop: VoidFunction
}

export { Modal }
export type { ModalProps, ModalRef }
