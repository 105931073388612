import { FC, useState } from 'react'
import { PaymentProvider } from '../../third-party/PaymentProvider'
import { Input } from '../Input'
import s from './CardExpiryInput.module.css'

const CardExpiryInput: FC<CardExpiryInputProps> = (props) => {
  const { name = 'cardExpiry' } = props

  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  return (
    <div className={s.root}>
      <Input
        size={2}
        required
        minLength={1}
        maxLength={2}
        pattern="\d+"
        id={PaymentProvider.formIds.CARD_EXPIRY_MONTH}
        onChange={setMonth}
      />
      /
      <Input
        size={4}
        required
        minLength={4}
        maxLength={4}
        pattern="\d+"
        id={PaymentProvider.formIds.CARD_EXPIRY_YEAR}
        onChange={setYear}
      />
      <input type="hidden" name={name} value={`${month}/${year}`} />
    </div>
  )
}

type CardExpiryInputProps = {
  name?: string
}

export { CardExpiryInput }
export type { CardExpiryInputProps }
