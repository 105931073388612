const CardFormatter: Formatter = class {
  static pattern = /\d{4} - \d{4} - \d{4} - \d{4}/

  static format(input: string) {
    const raw = this.getRaw(input)
    return raw.replaceAll('', (match, index: number, input: string) =>
      index && index < input.length && !(index % 4) ? ' - ' : ''
    )
  }

  static getRaw(input: string) {
    const digits = input.match(/\d/g)
    return digits ? digits.slice(0, 16).join('') : ''
  }
}

type Formatter = {
  pattern: RegExp
  format: (input: string) => string
  getRaw: (input: string) => string
}

export { CardFormatter }
