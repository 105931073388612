import dayjs from 'dayjs'
import { FC, Key } from 'react'
import { toTimeZoneString } from '../../helpers/date'
import s from './BookingClasses.module.css'

const BookingClasses: FC<BookingClassesProps> = (props) => {
  const { items } = props

  return (
    <ul>
      {items.map((item) => (
        <li key={item.key} className={s.item}>
          <div className={s.date}>
            {dayjs(item.startTime).format('MMM')}
            <br />
            {dayjs(item.startTime).format('DD')}
          </div>
          <div className={s.divider} />
          <div className={s.data}>
            <div className={s.name}>
              {item.name} with {item.staff}
            </div>
            <div className={s.time}>
              {dayjs(item.startTime).format('h:mm a')} -{' '}
              {dayjs(item.endTime).format('h:mm a')}
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

const mapBookingClasses = (sessions: Dto.Session[] = []): BookingClassItem[] =>
  sessions
    ? sessions.map((session) => ({
        key: session.id,
        name: session.category.name,
        staff: session.staff.first_name,
        startTime: toTimeZoneString(session.session_start_time_utc),
        endTime: toTimeZoneString(session.session_end_time_utc),
      }))
    : []

type BookingClassesProps = {
  items: BookingClassItem[]
}

type BookingClassItem = {
  key: Key
  name: string
  staff: string
  startTime: string
  endTime: string
}

export { BookingClasses, mapBookingClasses }
export type { BookingClassesProps, BookingClassItem }
