class PaymentProvider {
  static formIds = {
    SUBMIT_BUTTON: 'pay-submit',
    CUSTOMER_LAST_NAME: 'pay-last-name',
    CUSTOMER_REFERENCE: 'pay-reference',
    NAME_ON_CARD: 'pay-card-name',
    CARD_NUMBER: 'pay-card',
    CARD_EXPIRY_MONTH: 'pay-month',
    CARD_EXPIRY_YEAR: 'pay-year',
    CARD_CCV: 'pay-cvv',
    ACCOUNT_NAME: 'pay-account-name',
    ACCOUNT_BSB: 'pay-bsb',
    ACCOUNT_NUMBER: 'pay-account',
  }

  protected provider: PaymentProviderType

  constructor(provider: PaymentProviderType) {
    this.provider = provider
  }

  async init(options: PaymentOptionsType) {
    console.log('this is the parent init function')
  }

  handleResult(result: object): void {
    console.log('this is the parent handleResult function')
  }
}

type PaymentProviderType = 'ezidebit' | 'ezypay' | 'paychoice' | 'stripe'

type PaymentMethodType = 'card' | 'bank'

type PaymentOptionsType = {
  method: PaymentMethodType
  onSucceed: (result: object) => void
  handleError: (error: unknown) => void
}

export { PaymentProvider }
export type { PaymentProviderType, PaymentMethodType, PaymentOptionsType }
